export default [
  "Loïc Gros is an independent fashion designer from Paris, based in Berlin.",
  "His eponymous label, founded in 2019, combines gender-neutral silhouettes with future-forward styles, inspired by men classic pieces mixed with women silhouettes.",
  "<b>“I discovered I was into women’s fashion from a young age,” Gros recalls. “I was always seeking sensuality, romanticism, finesse, and elegance in design.”</b>",
  "With his grandmother’s blessing, who worked as a tailor, Gros enrolled at the Mode Estah Paris fashion school where he formally trained in sketching, pattern making, and sewing. During his studies, the young designer developed a keen eye for embellished men’s fashion.",
  "His eye for excellence would later land him positions at Dior Haute Couture and John Galliano, where he interned on the label’s seasonal collection.",
  "<b>“For my graduate presentation, I decided to develop a men’s collection with a feminine code. I used quality materials such as crepe, organza, silk, and velvet.”</b>",
  "Post-studies, Gros set sights on Berlin where he began developing his own made-to-measure garments. Early mockups catered to the city’s creative entrepreneurs, fusing weekend-ready looks with occasionwear pieces.",
  "Gros’ debut collection PREMIERE is available to purchase now."
]
