import React from "react"
import styled from "styled-components"
import withRoute from "../helpers/withRoute"

import Image from "./Image"

import contact from "../layout/contact"

const Container = styled.div`
  display: flex;
  padding: 25px 30px;
  padding-top: 130px;

  height: 100vh;

  h5 {
    margin-bottom: 3px;
  }

  a {
    color: ${props => props.theme.highlight};
    &:hover {
      color: ${props => props.theme.hover};
    }
  }
  > div {
    margin: auto;
    &:first-of-type {
      display: none;
    }
    width: 100%;
    text-align: center;
    @media (min-width: 1180px) {
      width: 50%;
      margin-right: 69px;
      &:first-of-type {
        margin-left: 69px;
        display: block;
      }
    }
  }
`

const RouteContact = () => {
  return (
    <Container>
      <div>
        <Image width="520px" height="730px" id="07" />
      </div>
      <div>
        <h5>Clothing</h5>
        <div>
          <span>Loïc Gros</span>
          <span> ― </span>
          <a href="mailto:loicxgros@gmail.com">{contact.email}</a>
        </div>

        <h5>Photography</h5>
        <div>
          <span>{contact.photographerName}</span>
          <span> ― </span>
          <a href={`mailto:${contact.photographerEmail}`}>
            {contact.photographerEmail}
          </a>
        </div>

        <h5>Website design & development</h5>
        <div>
          <span>{contact.authorName}</span>
          <span> ― </span>
          <a href="mailto:loicxgros@gmail.com">{contact.authorEmail}</a>
        </div>
      </div>
    </Container>
  )
}

export default withRoute(RouteContact)
