import React from "react"
import styled from "styled-components"
import withRoute from "../helpers/withRoute"

import Image from "./Image"

import biography from "../layout/biography"

const Container = styled.div`
  display: flex;
  padding: 25px 30px;
  padding-top: 130px;

  height: 100vh;

  p {
    font-size: 18px;
  }

  b {
    margin: 0 20px;
    color: ${props => props.theme.highlight};
    font-weight: 400;
  }

  > div {
    margin: auto;
    &:first-of-type {
      display: none;
    }
    width: 100%;
    @media (min-width: 1180px) {
      width: 50%;
      margin-right: 69px;
      &:first-of-type {
        display: block;
      }
    }
  }
`

const Text = styled.div`
  > p {
    max-width: 750px;
    margin-right: auto;
    margin-left: auto;
  }
`

const RouteContact = () => {
  return (
    <Container>
      <div>
        <Image width="520px" height="730px" id="25"></Image>
      </div>
      <div>
        <Text>
          {biography.map((paragraph, i) => (
            <p key={i}>
              <i dangerouslySetInnerHTML={{ __html: paragraph }} />
            </p>
          ))}
        </Text>
      </div>
    </Container>
  )
}

export default withRoute(RouteContact)
