import React from "react"
import { Route } from "react-router-dom"
import styled from "styled-components"

const Container = styled.div`
  /* padding: 25px 30px; */
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`

export default function(Component) {
  const WithRoute = ({ path, ...rest }) => (
    <Route path={path}>
      <Component {...rest} />
    </Route>
  )
  WithRoute.displayName = Component.displayName || Component.name
  return WithRoute
}
