import React from "react"
import styled from "styled-components"
import RouteHome from "./RouteHome"
import { Switch } from "react-router-dom"

import { withScrollProvider } from "../helpers/ScrollContext"
import Navbar from "./Navbar"
import RouteBiography from "./RouteBiography"
import RouteContact from "./RouteContact"

const Container = styled.div`
  /* display: flex; */
  height: 100%;
  box-sizing: border-box;
  /* flex-direction: row; */
  @media (min-width: ${props => props.theme.breaks.nav}) {
    flex-direction: row;
  }
`

const BaseLayout = () => {
  return (
    <Container>
      <Navbar />
      <Switch>
        <RouteHome exact path="/" />
        <RouteBiography path="/biography" />
        <RouteContact path="/contact" />
      </Switch>
    </Container>
  )
}

export default withScrollProvider(React.memo(BaseLayout))
