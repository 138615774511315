import "@babel/polyfill"

import React from "react"
import ReactDOM from "react-dom"

import { BrowserRouter } from "react-router-dom"
import { ThemeProvider } from "styled-components"

import { theme, GlobalStyles } from "./theme"
import BaseLayout from "./components/BaseLayout"

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <React.Fragment>
        <GlobalStyles />
        <BaseLayout />
      </React.Fragment>
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById("app")
)
