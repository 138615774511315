import { createGlobalStyle } from "styled-components"

export const theme = {
  highlight: "#d3b49e",
  hover: "#fda085",
  // highlight: "hsl(16, 100%, 50%)",
  // hover: "hsl(16, 100%, 80%)",
  breaks: {
    nav: "850px"
  }
}

export const GlobalStyles = createGlobalStyle`
  html {
    background: hsla(36,36%,96%,0.8);
  }
  html, body {
    width: 100%;
    /* background-image: radial-gradient(73% 147%, #EADFDF 59%, #ECE2DF 100%), radial-gradient(91% 146%, rgba(255,255,255,0.50) 47%, rgba(0,0,0,0.50) 100%);
    background-blend-mode: screen; */
    margin: 0;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  * {
    box-sizing: border-box;
  }
  button {
    color: inherit;
    cursor: pointer;
    border: none;
    background: none;
    &:focus {
      outline: none;
    }
  }
  body > * {
    font-family: 'Overpass', sans-serif;
    color: #0F2124;
  }
  h1 {
    font-family: 'Squada One', sans-serif;
    font-size: 2.1rem;
    letter-spacing: 5px;
    font-weight: 500;
  }
  h2 {
    line-height: 30px;
    letter-spacing: 0.1em;
    font-size: 18px;
    margin: 60px 16px 34px 16px;
    @media (min-width: ${props => props.theme.breaks.nav}) {
      margin: 92px 32px;
      line-height: 1.5;
      font-size: 24px;
    }
  }

  h5 {
    text-transform: uppercase;
  }
  p {
    font-size: 16px;
    margin: 12px;
    line-height: 26px;
    @media (min-width: ${props => props.theme.breaks.nav}) {
      margin: 16px;
      font-size: 20px;
      line-height: 32px;
    }
  }
`
