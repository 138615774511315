import React, { createContext, useState, useEffect } from "react"
export const ScrollContext = createContext()

export const withScroll = Component => props => {
  return (
    <ScrollContext.Consumer>
      {context => <Component {...context} {...props} />}
    </ScrollContext.Consumer>
  )
}

export const withScrollProvider = Component => props => {
  const [scrollHeight, set] = useState(0)

  useEffect(() => {
    window.addEventListener("scroll", () => set(window.scrollY))
    return window.removeEventListener("scroll", () => set(window.scrollY))
  }, [])

  return (
    <ScrollContext.Provider value={{ scrollHeight }}>
      <Component {...props} />
    </ScrollContext.Provider>
  )
}
