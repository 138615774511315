export default [["04", "07"], ["01"], ["11", "06"], ["16"], ["27", "26"]]

// export default [
//   {
//     flip: false,
//     image: "04",
//     text: `― The hand-crafted presentation highlights gender-neutral silhouettes and
//     traditional garment-making techniques through eight confident,
//     made-to-measure looks.`
//   },
//   {
//     flip: true,
//     image: "07",
//     text: `― All pieces are crafted from ivory silk, satin and twill, making for a light and glossy feel with classic-couture chic accents. Adaptability and ease of movement are at the heart of the garments’ designs with sensual takes on tailoring and sportswear.`
//   },
//   {
//     image: "01"
//   },
//   {
//     flip: false,
//     image: "11",
//     text: `― PREMIERE’s blouses and shirts cater to comfortable, everyday-wear needs with versatile styling options for the night-time. Select pieces feature strong, classic collars and voluminous, bias-cut Raglan sleeves, notably inspired by the Romantic period.`
//   },
//   {
//     flip: true,
//     image: "26",
//     text: `― For a more formal affair, choice options include the high-waist Dart fit trousers and deep crotch gusset pants. The bottoms are designed with a half-bias cut, two back pockets for essentials, and buttoned strap on the hip for a daring yet comfortable fit.`
//   },
//   {
//     image: "16"
//   },
//   {
//     flip: false,
//     image: "27",
//     text: `― PREMIERE’s athleisurewear bottoms confidently blur the boundaries between casual, elegant, and cosy. Be it running errands or mid-afternoon meetings, the elasticated-waist sports shorts, and ultralong high waist jogging pants are flexible choices donning side seam-stitching for maximum security and long wear.`
//   },
//   {
//     flip: true,
//     image: "25",
//     text: `― Admirers of elongated pieces can choose between the nightwear-inspired dressing gown and sleeveless dress which feature bespoke button detailing and open shoulders; perfect for draping and layering. For a seductive yet cosy feel, the PREMIERE crop-top and basketball-inspired tank top make for charming options.`
//   }
// ]
