import React, { useState, useEffect, useRef, memo } from "react"
import { useSpring, animated } from "react-spring"
import styled from "styled-components"

import { withScroll } from "../helpers/ScrollContext"
import { useWindowSize, selectWidth } from "../helpers/selectWidth"

import src from "../assets/portfolio/Loic AC *.jpg"
import preSrc from "../assets/sqip/Loic AC *.svg"

const Container = styled.div`
  flex-shrink: 0;
  width: ${props => props.width};
  height: ${props => props.height};
  min-height: ${props => props.minHeight};
  margin: auto;
  max-height: 100vh;
  max-width: 100vw;
  /* ${props =>
    props.height &&
    Object.keys(props.height).reduce((styles, key) => {
      if (key != 0)
        styles += `
          @media (min-width: ${key}px) {
            height: ${props.height[key]}px;
          }
        `
      return styles
    }, "")} */
`

const InnerContainer = styled.div`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  /* border: 1px solid red; */
`

const Img = styled(animated.div)`
  height: ${props => (props.scrolly ? 110 : 100)}%;
  width: 100%;
  background: url("${props => props.src}");
  background-size: cover;
  background-position: center;
`

const ImageContainer = props => {
  const size = useWindowSize()

  const [loaded, setLoaded] = useState(false)
  const { height, width } = props

  const [image] = useState(new Image())
  useEffect(() => {
    image.onload = () => {
      setLoaded(true)
    }
  }, [])
  useEffect(() => {
    if (props.onScreen) {
      image.src = `${src[props.id]}?nf_resize=fit&w=${selectWidth(
        size.width * props.resizeFactor
      )}`
    }
  }, [props.onScreen])

  const animateProps = useSpring({
    transform: `translateY(${-60 + props.dy * 30}px)`
  })
  // const animateProps = useSpring({
  //   transform: `translateY(${props.dy * -40}px)`
  // })
  // const animateProps = useSpring({
  //   transform: `translateY(
  //     ${props.flip ? props.dy * -30 : -150 + props.dy * 30}px
  //   )`
  // })
  return (
    <Container
      className={props.className}
      ref={props.innerRef}
      height={height || [props.heightFunc(size.width)]}
      minHeight={props.minHeight}
      width={width}
    >
      <InnerContainer>
        <Img
          style={props.scrolly && animateProps}
          scrolly={props.scrolly}
          src={preSrc[props.id]}
        >
          {loaded && props.onScreen && <Img src={image.src} />}
        </Img>
      </InnerContainer>
    </Container>
  )
}

ImageContainer.defaultProps = {
  resizeFactor: 1
}

const withBoundingRect = Component => ({ scrollHeight, ...props }) => {
  const ref = useRef(null)
  const [dy, setDy] = useState(0)
  const [onScreen, setOnScreen] = useState(false)
  useEffect(() => {
    const { top: y } = ref.current.getBoundingClientRect()
    if (y <= window.innerHeight) {
      if (y >= -window.innerHeight) {
        setOnScreen(true)
        props.scrolly && setDy(1 + y / window.innerHeight)
      } else {
        setOnScreen(false)
        props.scrolly && setDy(0)
      }
    } else {
      setOnScreen(false)
      props.scrolly && setDy(2)
    }
  }, [scrollHeight])
  return <Component {...props} innerRef={ref} dy={dy} onScreen={onScreen} />
}

export default withScroll(withBoundingRect(memo(ImageContainer)))
