import { useState, useEffect } from "react"

export function selectWidth(width) {
  // if (width < 321) {
  //   return 640
  // }
  if (width < 481) {
    return 960
  }
  if (width < 641) {
    return 1280
  }
  if (width < 961) {
    return 1920
  }
  if (width < 1281) {
    return 2560
  }
  return 3680
}

export function useWindowSize() {
  const isClient = typeof window === "object"
  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    }
  }
  const [windowSize, setWindowSize] = useState(getSize)
  useEffect(() => {
    if (!isClient) {
      return false
    }
  }, [])
  return windowSize
}
