import React from "react"
import Img from "./Image"
import styled from "styled-components"

// import src from "../assets/portfolio/Loic AC 04.jpg"
// import preSrc04 from "../assets/sqip/Loic AC 04.svg"
// import src from "../assets/portfolio/Loic AC *.jpg"
// import preSrc from "../assets/sqip/Loic AC *.svg"

const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin: auto;
  > div {
    margin: 32px;
  }
  align-items: center;
  @media (min-width: 1190px) {
    flex-direction: ${props => (props.flip ? "row-reverse" : "row")};
  }
  @media (max-width: 580px) {
    > div {
      margin: 32px 0;
    }
  }
`
const RouteHomeSideBoi = ({ images }) => {
  return (
    <Container>
      <Img
        scrolly
        width="520px"
        height="730px"
        id={images[0]}
        resizeFactor={0.5}
      />
      <Img
        scrolly
        width="520px"
        height="730px"
        id={images[1]}
        resizeFactor={0.5}
      />
    </Container>
  )
}

export default RouteHomeSideBoi
