import React, { useState, useCallback, useEffect } from "react"
import { Link, withRouter } from "react-router-dom"
import styled, { css } from "styled-components"

import { withScroll } from "../helpers/ScrollContext"
// import MobileMenu from "./NavbarMobileMenu"

const Container = styled.div`
  ${props =>
    props.bg &&
    `
    background: hsla(36,36%,96%, 1);
    box-shadow: 0px 0px 5px -3px rgba(0,0,0,01);
  `}
  transition-duration: 0.6s;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  > h1 {
    padding: 10px 30px;
    &:hover {
      color: ${props => props.theme.hover};
    }
    > button {
      @media (min-width: ${props => props.theme.breaks.nav}) {
        display: none;
      }
      margin-left: auto;
      color: ${props => props.theme.hover};
      border-radius: 9px;
      padding: 0px 13px;
      font-size: 0.5em;
      font-weight: 600;
      float: right;
    }
  }
`

// const ForDesktop = styled.div`
//   margin: 30px 0px;
//   padding: 0px 30px;
//   display: none;
//   @media (min-width: ${props => props.theme.breaks.nav}) {
//     display: block;
//   }
// `

const LinkContainer = styled.div`
  display: ${props => (props.isOpen ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: hsla(36, 36%, 96%, 1);
  padding: 20% 0;
  @media (min-width: ${props => props.theme.breaks.nav}) {
    padding: 0;
  }
  > a {
    cursor: pointer;
    flex: 1;
    display: flex;
    font-size: 1.5rem;
    @media (min-width: ${props => props.theme.breaks.nav}) {
      &:first-of-type {
        display: none;
      }
      display: inline;
      font-size: 1rem;
    }
    align-items: center;
    margin: 0px 30px;
    letter-spacing: 0.5px;
    &:hover {
      color: ${props => props.theme.hover};
    }
    ${props =>
      props.active &&
      css`
        color: ${props => props.theme.highlight};
      `}
  }

  @media (min-width: ${props => props.theme.breaks.nav}) {
    display: block;
    margin: 40px 0px;
    position: relative;
    background: none;
  }
`

const MenuButton = styled.button`
  position: fixed;
  font-size: 1rem;
  right: 20px;
  top: 40px;
  @media (min-width: ${props => props.theme.breaks.nav}) {
    display: none;
  }
`

const Navbar = props => {
  // const pathname = props?.location?.pathname
  const navigate = useCallback(
    to => () => {
      setOpen(false)
      props.history.push(to)
    },
    []
  )

  const [isOpen, setOpen] = useState(false)
  const onClick = useCallback(open => () => setOpen(open), [])

  return (
    <Container bg={props.bg}>
      <h1>
        <Link to="/">Loïc Gros</Link>
      </h1>
      <MenuButton onClick={onClick(true)}>Menu</MenuButton>
      <LinkContainer isOpen={isOpen}>
        <a onClick={navigate("/")}>Home</a>
        <MenuButton onClick={onClick(false)}>Close</MenuButton>
        <a onClick={navigate("/biography")}>Biography</a>
        <a onClick={navigate("/contact")}>Contact</a>
      </LinkContainer>
    </Container>
  )
}

const withBackgroundBool = Component => ({
  scrollHeight,
  location,
  ...props
}) => {
  const [bg, setBg] = useState(false)
  useEffect(() => {
    const scrolly =
      scrollHeight > 500 &&
      scrollHeight < document.body.offsetHeight - window.innerHeight
    return setBg(scrolly)
  }, [scrollHeight, location?.pathname])

  return <Component bg={bg} {...props} />
}

export default withScroll(withRouter(withBackgroundBool(React.memo(Navbar))))
