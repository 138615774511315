import React from "react"
import styled from "styled-components"

import withRoute from "../helpers/withRoute"

import Img from "./Image"

import RouteHomeSideBoi from "./RouteHomeSideBoi"

import homepage from "../layout/homepage"
import OneScreen from "../helpers/OneScreen"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 500px) {
    align-items: flex-start;
  }
`

const FullWidthText = styled.h2`
  font-weight: 300;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  display: flex;
  margin: auto;
  flex: 1;
  padding: 32px;
  max-width: 1000px;
`

const RouteHome = () => {
  return (
    <Container>
      <OneScreen>
        <Img width="100%" height="100vh" id="03" />
      </OneScreen>
      {homepage.map(item =>
        item.length === 2 ? (
          <RouteHomeSideBoi key={item[0]} images={item} />
        ) : (
          <Img
            key={item[0]}
            scrolly
            width="100%"
            height="calc(100vh - 101px)"
            id={item[0]}
          />
        )
      )}
      <OneScreen>
        <Img scrolly width="100%" height="100vh" id="13" />
      </OneScreen>
    </Container>
  )
}

export default withRoute(RouteHome)
